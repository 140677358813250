<template>
  <section
    class="d-flex flex-column flex-sm-row align-sm-center justify-sm-space-between flex-wrap flex-md-nowrap"
  >
    <v-card
      v-for="(totalizador, i) in totalizadores"
      :key="totalizador.key"
      class="ma-2 d-flex flex-column w-full"
      elevation="0"
    >
      <v-card-title
        :style="{ backgroundColor: totalizador.color }"
      ></v-card-title>

      <v-card-text>
        <div class="d-flex flex-row align-center justify-space-around mt-4">
          <h3 class="font-weight-bold">{{ totalizador.titulo }}</h3>

          <p class="subtitle-1 ma-0 pa-0">
            {{ dados && dados[i] ? dados[i] : "0" }}
          </p>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "TotalizadorComponent",

  props: ["dados"],

  computed: {
    totalizadores: {
      get() {
        return this.$store.getters["avd/getTotalizadores"];
      },
    },
  },
};
</script>

<style scoped>

.w-full {
  width: 100%;
}
</style>
