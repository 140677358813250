<template>
  <v-expansion-panels accordion>
    <v-expansion-panel class="ma-4">
      <v-expansion-panel-header> Filtro </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div class="d-flex flex-column align-end">
          <div class="d-flex flex-column">
            <div
              class="d-flex flex-column flex-md-row flex-wrap flex-md-nowrap justify-md-space-around align-md-center"
            >
              <!-- Pesquisar avaliação -->
              <v-text-field
                class="white ma-2"
                hide-details="true"
                outlined
                label="Pesquisar Avaliação"
                placeholder="Ex.: Avaliação 01"
                v-model="nomePesquisa"
              ></v-text-field>
              <!-- Responsavel -->
              <v-select
                class="ma-2 white"
                :items="responsaveis"
                item-text="nome"
                item-value="id"
                label="Responsável"
                outlined
                hide-details="true"
                v-model="responsavel"
              ></v-select>
              <!-- Tipos de avaliacao -->
              <v-select
                class="ma-2 white"
                :items="tipos"
                item-text="tipo"
                item-value="tipo"
                label="Tipos"
                outlined
                hide-details="true"
                v-model="tipo"
              ></v-select>

              <!-- Data -->
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataFormated"
                    label="Período"
                    prepend-icon="mdi-calendar"
                    hide-details="true"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-BR"
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </div>

            <!-- Pontuacao -->
            <v-card
              style="background-color: white !important"
              elevation="0"
              outlined
              class="ma-2"
            >
              <v-card-subtitle>Pontuação</v-card-subtitle>

              <v-card-text>
                <v-range-slider
                  v-model="range"
                  :max="max"
                  :min="min"
                  hide-details
                  class="align-center"
                >
                  <template v-slot:prepend>
                    <v-text-field
                      label="Mínimo"
                      :value="range[0]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 50px"
                      @change="$set(range, 0, $event)"
                    ></v-text-field>
                  </template>
                  <template v-slot:append>
                    <v-text-field
                      label="Máximo"
                      :value="range[1]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 50px"
                      @change="$set(range, 1, $event)"
                    ></v-text-field>
                  </template>
                </v-range-slider>
              </v-card-text>
            </v-card>
          </div>

          <v-btn
            class="px-2 py-6 ma-2"
            color="#162c40"
            dark
          >
            Filtrar avaliações
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";

export default {
  name: "FiltroAvaliacaoComponent",
  data() {
    return {
      responsaveis: [
        {
          id: 1,
          nome: "João Silva",
        },
        {
          id: 2,
          nome: "Maria Silva",
        },
        {
          id: 3,
          nome: "Roberta Alves",
        },
      ],
      tipos: [
        {
          tipo: "Autoavaliação",
        },
        {
          tipo: "Avaliação de pares",
        },
        {
          tipo: "Avaliação do gestor",
        },
        {
          tipo: "Avaliação do cliente",
        },
      ],

      nomePesquisa: "",
      responsavel: null,
      tipo: null,
      date: null,
      range: [0, 100],

      menu: false,
      min: 0,
      max: 100,
    };
  },

  computed: {
    dataFormated() {
      let date = moment(this.date);
      if (date.isValid()) {
        return date.format("DD/MM/YYYY");
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
