<template>
  <section>
    <div class="d-flex flex-column">
      <TotalizadorComponent :dados="dadosTotalizador" />

      <div class="mx-4">
        <FiltroAvaliacaoComponent />
      </div>
    </div>
    <EstruturaAvaliacaoComponent :items="avaliacoes" />
  </section>
</template>

<script>
import EstruturaAvaliacaoComponent from "./components/EstruturaAvaliacaoComponent.vue";
import FiltroAvaliacaoComponent from "./components/FiltroAvaliacaoComponent.vue";
import TotalizadorComponent from "./components/TotalizadorComponent.vue";

export default {
  name: "Avaliacoes",
  components: {
    EstruturaAvaliacaoComponent,
    FiltroAvaliacaoComponent,
    TotalizadorComponent,
  },

  data() {
    return {
      dialog: false,
    };
  },

  created() {
    this.$store.dispatch("avd/buscarPainelAvaliacao");
  },

  computed: {
    avaliacoes: {
      get() {
        return this.$store.getters["avd/getPainelAvaliacoes"];
      },
    },
    dadosTotalizador: {
      get() {
        return this.$store.getters[
          "avd/getDadosTotalizadores"
        ];
      },
    },
  },
};
</script>

<style></style>
